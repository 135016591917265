import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import {
  Carousel,
  Container,
  Pagination,
  Typography,
} from "components/elements";
import { v4 as uuidv4 } from "uuid";
import { Colors } from "styles/theme/color";
import useResponsive from "utils/use-media-query";
import ServiceButton from "components/pages/product/customer-service";
import News from "components/pages/product/news";
import FAQ from "components/pages/product/faq";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Eye, Search, X } from "lucide-react";
import CurrencyFormatter from "utils/currency-formatter";
import axios from "axios";
import Loading from "components/layout/loading";

const StyledTextField = styled(TextField)({
  width: "90%",
  "& .MuiOutlinedInput-root": {
    padding: "0px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
});

const Product = () => {
  const { tablet, laptop } = useResponsive();
  const navigate = useNavigate();

  // Initialze State
  const [filter, setFilter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(12);
  const [error, setError] = useState("");
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const filters = [
    {
      background: Colors.gradientColorBlue,
      title: "Untuk Kamu",
      category: 0,
    },
    {
      background: Colors.gradientColorOrange,
      title: "Food & Snack",
      category: 1,
    },
    {
      background: Colors.gradientColorBrown,
      title: "Drink &  Ice",
      category: 2,
    },
    {
      background: Colors.gradientColorDiamond,
      title: "Furniture & Accessories",
      category: 3,
    },
  ];

  // Paginate Data
  const getPaginatedData = (data) => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    const projectData = [...data];

    const final = projectData.slice(startIndex, endIndex);

    return final;
  };

  const handleName = (target) => {
    const { name, weight, variant, flavor } = target;

    return `${name} ${weight ? "-" + weight : ""} ${
      variant ? "-" + variant : ""
    } ${flavor ? "-" + flavor : ""}`;
  };

  useEffect(() => {
    const getAllData = async () => {
      try {
        const { data } = await axios.get(
          "https://umkm-server.vercel.app/product/list"
        );
        setData(data);
      } catch (err) {
        setError(err);
      }
    };

    getAllData();
  }, []);

  if (!data) return <Loading />;

  return (
    <main>
      <Container padding={laptop ? "100px 120px 64px" : "128px 24px 0px"}>
        {/* Carousel Head */}
        <Carousel />

        {/* Filter */}
        <Box
          display="flex"
          alignItems="start"
          justifyContent="space-between"
          marginTop="16px"
          overflow="scroll"
        >
          <Box display="flex" alignItems="center" gap="16px">
            {filters.map((item) => (
              <Box
                key={uuidv4()}
                height="84px"
                width="200px"
                borderRadius="12px"
                padding="10px 16px"
                sx={{ cursor: "pointer", background: item.background }}
                onClick={() => setFilter(item.category)}
              >
                <Box
                  height="3px"
                  borderRadius="2px"
                  width={filter === item.category ? "84px" : "0px"}
                  bgcolor={Colors.white}
                  marginBottom="8px"
                  sx={{ transition: "0.5s all ease" }}
                />

                <Typography type="body2" color={Colors.white} fontWeight={700}>
                  {item.title}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Search */}
          {tablet && (
            <Box
              display="flex"
              alignItems="center"
              border={`1px solid ${Colors.black}`}
              borderRadius="27px"
              padding="0px 12px"
              height="48px"
              width="237px"
            >
              <Search />

              <StyledTextField
                placeholder="Keripik"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value.toLocaleLowerCase());
                  setCurrentPage(1);
                }}
              />

              {search && (
                <IconButton onClick={() => setSearch("")}>
                  <X size="16px" style={{ cursor: "pointer" }} />
                </IconButton>
              )}
            </Box>
          )}
        </Box>

        {!tablet && (
          <Box
            display="flex"
            alignItems="center"
            border={`1px solid ${Colors.black}`}
            borderRadius="27px"
            padding="0px 12px"
            height="48px"
            width="177px"
            marginTop="24px"
          >
            <Search />

            <StyledTextField
              placeholder="Keripik"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
            />

            {search && (
              <IconButton onClick={() => setSearch("")}>
                <X size="16px" style={{ cursor: "pointer" }} />
              </IconButton>
            )}
          </Box>
        )}

        {/* Product */}
        <Grid container sx={{ marginTop: "12px" }} rowSpacing={3} gap="13px">
          {getPaginatedData(
            data
              ?.filter((item) =>
                search === "" ? item : item.name.toLowerCase().includes(search)
              )
              .filter((val) => (filter === 0 ? val : val.category === filter))
          )?.map((el) => (
            <Grid item key={uuidv4()}>
              <Box
                position="relative"
                width="188px"
                borderRadius="8px"
                sx={{
                  boxShadow: Colors.shadow,
                  transition: "0.7s all ease",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.03)",
                    boxShadow: Colors.shadowOrange,
                  },
                }}
                onClick={() => navigate(`/produk-umkm/${el.id}`)}
              >
                <Box
                  position="absolute"
                  top={10}
                  left={0}
                  sx={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    background: Colors.secondaryRed100,
                  }}
                  display="flex"
                  color={Colors.white}
                  alignItems="center"
                  padding="0px 8px"
                  gap="3px"
                  height="23px"
                  fontSize={14}
                >
                  <Eye size={16} /> {el.views}
                </Box>
                <img
                  alt="product"
                  width="188px"
                  height="188px"
                  src={el.thumbnail}
                  style={{
                    objectFit: "cover",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />

                <Box padding="0px 8px 12px">
                  <Typography type="body5" height="36px">
                    {handleName(el).substring(0, 48).concat("...")}
                  </Typography>
                  <Typography type="heading6" marginTop="8px">
                    {el.price ? CurrencyFormatter(el.price) : "-"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Pagination */}
        <Box display="flex" justifyContent="center" marginTop="32px">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageLimit={Math.ceil(
              data
                ?.filter((item) =>
                  search === ""
                    ? item
                    : item.name.toLowerCase().includes(search)
                )
                .filter((val) => (filter === 0 ? val : val.category === filter))
                ?.length / dataLimit
            )}
          />
        </Box>

        {/* Customer service */}
        <ServiceButton />
      </Container>

      {/* News */}
      <Box marginTop={tablet ? "0px" : "48px"}>
        <News />
      </Box>

      {/* FAQ */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <FAQ />
      </Box>
    </main>
  );
};

export default Product;
