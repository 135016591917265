import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import useResponsive from "utils/use-media-query";
import { Colors } from "styles/theme/color";
import { Button, Container } from "components/elements";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Loading from "components/layout/loading";
import { getLocalStorage } from "utils/local-storage";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Component = styled(Box)(
  ({ padding }) => `
    background-color: ${Colors.white};
    box-shadow: 0px 10px 40px rgba(164, 149, 107, 0.1);
    padding: ${padding};
    border-radius: 20px;
    outline: none;
    box-sizing: border-box; /* Ensure proper sizing */
  `
);

const ProductCreation = () => {
  const { id } = useParams();
  const { laptop } = useResponsive();
  const navigate = useNavigate();
  const access_token = getLocalStorage("access_token");

  // State to capture form input
  const [payload, setPayload] = useState({
    OwnerId: "",
    name: "",
    weight: "",
    price: "",
    variant: "",
    stock: "",
    views: "",
    likes: "",
    flavor: "",
    content: "",
    thumbnail: "",
    assets: null,
    category: "",
  });
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [owners, setOwners] = useState([]);
  const [content, setContent] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  // Handle form input change
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Generate a URL for the selected file for preview
    if (selectedFile) {
      setPreview(URL.createObjectURL(selectedFile));
    }
  };

  // Handle form submission
  const handleCreate = async (e) => {
    e.preventDefault();

    if (!file && !id) {
      setError("Thumbnail Cannot be Empty");
      return;
    }

    if (!content) {
      setError("product Detail Cannot be Empty");
      return;
    }

    setLoading(true);
    try {
      const url = id ? `/update/${id}` : "/create";

      const formData = new FormData();
      formData.append("OwnerId", payload.OwnerId);
      formData.append("name", payload.name);
      formData.append("weight", +payload.weight);
      formData.append("price", +payload.price);
      formData.append("variant", payload.variant);
      formData.append("stock", +payload.stock);
      formData.append("flavor", payload.flavor);
      formData.append("content", content);
      formData.append("category", +payload.category);
      formData.append("likes", +payload.likes || 0);
      formData.append("views", +payload.views || 0);
      formData.append("assets", payload.assets);
      if (file) {
        formData.append("thumbnail", file);
      }

      await axios.post(
        "https://umkm-server.vercel.app/product" + url,
        formData,
        {
          headers: {
            access_token: JSON.parse(access_token),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      navigate("/backoffice-product");
    } catch (e) {
      setLoading(false);
      setError(e.response?.data?.message || "Error creating owner");
    }
  };

  const getDataDetail = async () => {
    try {
      const { data } = await axios.get(
        `https://umkm-server.vercel.app/product/${id}`
      );
      setPayload({ ...data });
      setContent(data.content);
    } catch (err) {
      setError(err);
    }
  };

  const getAllOwner = async () => {
    try {
      const { data } = await axios.get(
        "https://umkm-server.vercel.app/product/owners"
      );
      setOwners(data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (!access_token) navigate("/produk-umkm");
  }, []);

  useEffect(() => {
    getAllOwner();
  }, []);

  useEffect(() => {
    if (id) getDataDetail();
  }, [id]);

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  if (loading) return <Loading />;

  return (
    <Container
      style={{ width: "100%", boxSizing: "border-box" }}
      padding={laptop ? "100px 120px 64px" : "128px 24px 0px"}
    >
      <Box
        display="flex"
        justifyContent="end"
        marginBottom="18px"
        onClick={() => navigate(-1)}
      >
        <Button
          label="⇚ Kembali"
          height="48px"
          width="150px"
          borderRadius="8px"
          type="secondary"
          submit
        />
      </Box>
      <Component padding="32px 63px">
        <Typography variant="h1" fontSize={24} fontWeight={700}>
          {id ? "Perbahari" : "Tambahkan"} Produk
        </Typography>

        <form onSubmit={handleCreate}>
          <Box display="flex" justifyContent="space-between" marginTop="32px">
            <Box width="45%">
              <TextField
                name="name"
                type="text"
                placeholder="Es Ronaldo"
                label="Nama"
                sx={{ marginBottom: "24px" }}
                required
                fullWidth
                value={payload.name}
                onChange={handleChange}
              />
              <TextField
                name="weight"
                type="number"
                placeholder="250"
                label="Berat (g)"
                sx={{ marginBottom: "24px" }}
                fullWidth
                value={payload.weight}
                onChange={handleChange}
              />
              <TextField
                name="variant"
                type="text"
                placeholder="Roti O"
                label="Varian"
                sx={{ marginBottom: "24px" }}
                fullWidth
                value={payload.variant}
                onChange={handleChange}
              />
              <TextField
                name="flavor"
                type="text"
                placeholder="bawang dan terasi"
                label="Rasa (jenis)"
                sx={{ marginBottom: "24px" }}
                fullWidth
                value={payload.flavor}
                onChange={handleChange}
              />
            </Box>
            <Box width="45%">
              <TextField
                name="price"
                type="number"
                placeholder="8000"
                label="Harga"
                sx={{ marginBottom: "24px" }}
                required
                fullWidth
                value={payload.price}
                onChange={handleChange}
              />
              <TextField
                name="stock"
                type="number"
                placeholder="100"
                label="Stok"
                sx={{ marginBottom: "24px" }}
                fullWidth
                required
                value={payload.stock}
                onChange={handleChange}
              />
              <FormControl fullWidth sx={{ marginBottom: "24px" }}>
                <InputLabel id="demo-simple-select-label">Kategori*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payload.category}
                  label="Kategori*"
                  onChange={handleChange}
                  name="category"
                  required
                >
                  <MenuItem value={1}>Food & Snack</MenuItem>
                  <MenuItem value={2}>Drink & Ice</MenuItem>
                  <MenuItem value={3}>Furniture & Accessories</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Pemilik*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payload.OwnerId}
                  label="Pemilik*"
                  onChange={handleChange}
                  name="OwnerId"
                  required
                >
                  {owners?.map((element) => (
                    <MenuItem key={uuidv4()} value={element.id}>
                      {element.owner}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {id && !isEdit ? (
            <Box display="flex" flexDirection="column" width="45%">
              <Typography variant="body1" marginBottom="12px">
                Thumbnail*
              </Typography>

              <img
                alt="thumbnail"
                src={payload.thumbnail}
                height="200px"
                style={{
                  objectFit: "contain",
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "12px",
                }}
              />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" width="45%">
              <Typography variant="body1" marginBottom="12px">
                Upload Thumbnail*
              </Typography>
              {preview && (
                <img
                  alt="thumbnail"
                  src={preview}
                  height="200px"
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "12px",
                  }}
                />
              )}
              <input type="file" onChange={handleFileChange} />
            </Box>
          )}

          {id && (
            <Button
              margin="12px 0px"
              label={isEdit ? "Batal" : "Edit Thumbnail"}
              height="48px"
              borderRadius="8px"
              type={isEdit ? "secondary" : "primary"}
              onClick={() => setIsEdit(!isEdit)}
            />
          )}

          <Typography marginBottom="12px" marginTop="24px">
            Detail Produk*
          </Typography>
          <ReactQuill theme="snow" value={content} onChange={setContent} />

          <Box
            marginY="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="12px"
          >
            <Button
              label="Batal"
              height="48px"
              width="170px"
              borderRadius="24px"
              type="button"
              onClick={() =>
                setPayload({
                  owner: "",
                  phone_number: "",
                  instagram: "",
                  shopee: "",
                  address: "",
                  tokopedia: "",
                  optional: "",
                })
              }
            />
            <Button
              label="Submit"
              height="48px"
              width="170px"
              borderRadius="24px"
              submit
            />
          </Box>
        </form>

        {error && (
          <Typography
            color="error"
            variant="body2"
            marginTop="16px"
            textAlign="center"
          >
            {error}
          </Typography>
        )}
      </Component>
    </Container>
  );
};

export default ProductCreation;
