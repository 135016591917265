import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography as Label,
  Button as MyButton,
} from "@mui/material";
import { Button, Container, Typography } from "components/elements";
import useResponsive from "utils/use-media-query";
import { Colors } from "styles/theme/color";
import styled from "@emotion/styled";
import Shopee from "assets/logo/shopee.png";
import Tokopedia from "assets/logo/tokopedia.png";
import Instagram from "assets/logo/instagram.webp";
import {
  Eye,
  Headset,
  Heart,
  Link,
  MessageCircleMore,
  Minus,
  Plus,
  Share2,
  Store,
} from "lucide-react";
import CurrencyFormatter from "utils/currency-formatter";
import axios from "axios";
import Loading from "components/layout/loading";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "utils/local-storage";
import { v4 as uuidv4 } from "uuid";

const ProductDetail = () => {
  const { id } = useParams();
  const { tablet, laptop, laptop2 } = useResponsive();
  const isLike = id ? getLocalStorage(id) : false;
  //  Initialize state
  const [isReadMore, setIsReadMore] = useState(false);
  const [count, setCount] = useState(1);
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const [isCopy, setIsCopy] = useState();
  const [preview, setPreview] = useState();
  const maxBuy = 10;

  const Content = styled.div(
    ({ laptop, tablet }) => `
    color: ${Colors.textPrimary};
    font-family: Anek Bangla;

    & ul {
      padding-top: 0px;
      padding-bottom: 0px;
      margin: 0px;
    }

    & p,li {
      padding: 0px;
      margin: 0px;
      font-family: Mulish;
      font-size: ${laptop ? "14px" : "12px"};
      font-weight: 400;
      line-height: 150%;
    }
  
    & h1 {
      margin: 0px;
      font-family: Anek Bangla;
      font-size: ${laptop ? "32px" : "28px"};
      font-weight: 700;
      line-height: 48px;
    }
  
    & h2 {
      margin: 0px;
      font-family: Anek Bangla;
      font-size: ${laptop ? "24px" : "22px"};
      font-weight: 700;
      line-height: 130%;
    }
  
    & h3 {
      margin: 0px;
      font-family: Anek Bangla;
      font-size: ${laptop ? "18px" : "16px"};
      font-weight: 700;
      line-height: 100%;
    }
  
    & a {
      color: ${Colors.primaryColorBlue100};
    }
  
    & img {
      width: 100%;
      height: ${tablet ? "360px" : "220px"};
      object-fit: cover;
      display: block;
      margin: auto;
      border-radius: 12px;
      margin: 12px 0px;
    }
  `
  );

  const LinktoDetail = styled.span`
    color: ${Colors.primaryBrown100};
    cursor: pointer;
    transition: all ease 0.3s;
    font-weight: 600;
    &:hover {
      color: ${Colors.primaryOrange80};
    }
  `;

  const handleName = (target) => {
    const { name, weight, variants, flavors } = target;

    return `${name} ${weight ? "- " + weight : ""} ${
      variants ? "- " + variants : ""
    } ${flavors ? "- " + flavors : ""}`;
  };

  const handleClickCopy = () => {
    setIsCopy(true);
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);

    setTimeout(() => {
      setIsCopy(false);
    }, 1000);
  };

  const getDataDetail = async () => {
    try {
      const { data } = await axios.get(
        `https://umkm-server.vercel.app/product/${id}`
      );
      setData(data);
    } catch (err) {
      setError(err);
    }
  };

  const handleClickLove = async () => {
    try {
      let resp;

      if (isLike) {
        resp = await axios.get(
          `https://umkm-server.vercel.app/like/${id}?status=dislike`
        );
        removeLocalStorage(id);
      } else {
        resp = await axios.get(
          `https://umkm-server.vercel.app/like/${id}?status=like`
        );

        setLocalStorage(id, 1);
      }
      if (resp) setData(resp.data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    getDataDetail();
  }, [id]);

  // Trim Project Updates Content
  const handleTrimContent = (value) => {
    return (
      <Box>
        <Content
          laptop={laptop}
          tablet={tablet}
          dangerouslySetInnerHTML={{
            __html: value.slice(0, 200).concat("..."),
          }}
        />
        <Typography type="body4">
          <LinktoDetail onClick={() => setIsReadMore(true)}>
            Lihat selengkapnya
          </LinktoDetail>
        </Typography>
      </Box>
    );
  };

  const handleShowAssets = (value) => {
    const assets = JSON.parse(value);

    if (JSON.parse(value)?.length) {
      return (
        <>
          {assets.map((url, idx) => (
            <img
              key={uuidv4()}
              alt={"assets" + idx}
              src={url}
              width="74px"
              height="74px"
              style={{
                cursor: "pointer",
                borderRadius: "7px",
                objectFit: "cover",
                border: `2px solid ${
                  preview == url ? Colors.primaryOrange100 : Colors.white
                }`,
              }}
              onClick={() => setPreview(url)}
            />
          ))}
        </>
      );
    }
    return "";
  };

  if (!data) return <Loading />;

  return (
    <Container padding={laptop ? "120px 88px 0px" : "128px 24px 0px"}>
      <Box
        display={laptop ? "flex" : "grid"}
        justifyContent="center"
        padding="0px 32px"
        gap="32px"
        marginLeft={laptop ? "0px" : "28px"}
      >
        <Box width={laptop ? "40%" : "100%"}>
          <img
            alt={data.name}
            src={preview || data.thumbnail}
            width={laptop2 ? "480px" : "320px"}
            height={laptop2 ? "420px" : "240px"}
            style={{
              borderRadius: "15px",
              objectFit: "cover",
            }}
          />
          <Box
            display="flex"
            gap="8px"
            overflow="auto"
            sx={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                width: "0",
              },
            }}
          >
            <Box>
              <img
                alt={data.name}
                src={data.thumbnail}
                width="74px"
                height="74px"
                style={{
                  cursor: "pointer",
                  borderRadius: "7px",
                  objectFit: "cover",
                  border: `2px solid ${
                    !preview ? Colors.primaryOrange100 : Colors.white
                  }`,
                }}
                onClick={() => setPreview(null)}
              />
            </Box>
            {handleShowAssets(data.assets)}
          </Box>

          <Box
            marginTop="4px"
            display="flex"
            justifyContent="end"
            paddingX={tablet ? "0px" : "32px"}
          >
            <Box
              boxShadow={Colors.shadowSketch}
              borderRadius="8px"
              padding="4px 8px"
              display="flex"
              alignItems="center"
              gap={tablet ? "12px" : "4px"}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection={tablet ? "column" : "row"}
              >
                <IconButton onClick={handleClickLove}>
                  <Heart
                    size={tablet ? 24 : 16}
                    color={Colors.secondaryRed100}
                    style={{ fill: isLike ? Colors.secondaryRed100 : "none" }}
                  />
                </IconButton>

                <Label
                  fontSize={tablet ? 14 : 10}
                  color={Colors.textColorSecondary}
                >
                  LIKES:{" "}
                  <span style={{ fontWeight: 600, color: Colors.textPrimary }}>
                    {data.likes}
                  </span>
                </Label>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection={tablet ? "column" : "row"}
              >
                <IconButton>
                  <Eye size={tablet ? 24 : 16} color={Colors.primaryBlue} />
                </IconButton>
                <Label
                  fontSize={tablet ? 14 : 10}
                  color={Colors.textColorSecondary}
                >
                  VIEWS:{" "}
                  <span style={{ fontWeight: 600, color: Colors.textPrimary }}>
                    {data.views}
                  </span>
                </Label>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box width={laptop ? "50%" : "100%"}>
          <Typography
            type="heading4"
            textAlign="left"
            lineHeight="130%"
            color={Colors.textColorPrimary}
          >
            {handleName(data)}
          </Typography>
          <Typography
            type={laptop ? "heading1" : "heading3"}
            textAlign="left"
            lineHeight="130%"
            color={Colors.textColorPrimary}
          >
            {CurrencyFormatter(data.price)}
          </Typography>

          <Box marginTop="18px">
            <Box
              paddingY="12px"
              sx={{
                borderTop: `2px solid ${Colors.grey}`,
                borderBottom: `2px solid ${Colors.grey}`,
              }}
            >
              <Label fontSize="18px" color={Colors.primaryOrange100}>
                Detail Produk
              </Label>
              <Box
                bgcolor={Colors.primaryOrange100}
                width="110px"
                height="3px"
                borderRadius="3px"
              />
            </Box>
            <Box
              marginTop="12px"
              paddingBottom="12px"
              sx={{
                borderBottom: `2px solid ${Colors.grey}`,
              }}
            >
              {isReadMore ? (
                <>
                  <Content
                    laptop={laptop}
                    tablet={tablet}
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                  />

                  <Typography
                    type={laptop ? "body4" : "body5"}
                    color={Colors.textSecondary}
                  >
                    <LinktoDetail
                      onClick={() => {
                        setIsReadMore(false);
                      }}
                    >
                      ...Lihat Lebih Singkat
                    </LinktoDetail>
                  </Typography>
                </>
              ) : (
                <Box>{handleTrimContent(data.content)}</Box>
              )}
            </Box>
            <Box
              display={laptop ? "flex" : "grid"}
              paddingY="12px"
              sx={{
                borderBottom: `2px solid ${Colors.grey}`,
              }}
            >
              <Box
                borderRight={`2px solid ${Colors.grey}`}
                paddingRight="24px"
                marginRight="24px"
              >
                <Label
                  fontSize="14px"
                  color={Colors.textPrimary}
                  marginBottom="8px"
                >
                  Atur jumlah dan catatan
                </Label>
                <Box display="flex" alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    borderRadius="12px"
                    boxShadow={Colors.shadow}
                    padding="2px 4px"
                    justifyContent="center"
                    width="110px"
                  >
                    <IconButton
                      onClick={() => (count > 1 ? setCount(count - 1) : "")}
                    >
                      <Minus
                        color={
                          count > 1 ? Colors.textColorPrimary : Colors.darkGrey
                        }
                      />
                    </IconButton>
                    <Label minWidth="10px">{count}</Label>
                    <IconButton
                      onClick={() =>
                        count < data.stock ? setCount(count + 1) : ""
                      }
                    >
                      <Plus
                        color={
                          count < data.stock
                            ? Colors.textColorPrimary
                            : Colors.darkGrey
                        }
                      />
                    </IconButton>
                  </Box>
                  <Label fontSize={14} fontWeight={400} marginLeft="8px">
                    Stok:{" "}
                    <span style={{ fontWeight: 700, fontSize: 16 }}>
                      {data.stock}
                    </span>
                  </Label>
                </Box>
                <Label
                  fontSize="14px"
                  color={Colors.textSecondary}
                  marginTop="8px"
                >
                  Max. pembelian {maxBuy} pcs
                </Label>

                <Box
                  display="flex"
                  width="300px"
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom="12px"
                >
                  <Label
                    fontSize="14px"
                    color={Colors.textPrimary}
                    marginTop="8px"
                  >
                    Subtotal
                  </Label>
                  <Label
                    fontFamily="Anek Bangla"
                    fontSize="20px"
                    fontWeight={700}
                    color={Colors.textColorPrimary}
                    marginTop="8px"
                  >
                    {CurrencyFormatter(count * data.price)}
                  </Label>
                </Box>

                <Button
                  label="Beli ☎"
                  height="48px"
                  width="100%"
                  borderRadius="12px"
                  onClick={() =>
                    window.open(
                      `https://wa.me/${data.Owner?.phone_number}?text=Hai saya ingin membeli ${data.name} - sebanyak ${count}`,
                      "_blank"
                    )
                  }
                />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="32px"
                  marginTop="12px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        `https://wa.me/${data.Owner?.phone_number}?text=Hai saya ingin membeli ${data.name}`,
                        "_blank"
                      )
                    }
                  >
                    <MessageCircleMore />
                    Chat Penjual
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    sx={{ cursor: "pointer" }}
                    onClick={handleClickCopy}
                  >
                    <Share2 />
                    {isCopy ? "link dicopy" : "Bagikan"}
                  </Box>
                </Box>
              </Box>

              <Box marginTop={laptop ? "0px" : "24px"}>
                <Box
                  display="flex"
                  marginBottom="12px"
                  alignItems="center"
                  gap="8px"
                >
                  <Box
                    width={38}
                    height={38}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="100%"
                    border={`1px solid ${Colors.grey}`}
                    boxShadow={Colors.shadow}
                  >
                    <Store color={Colors.textColorPrimary} />
                  </Box>

                  <Box>
                    <Label lineHeight="100%" color={Colors.textPrimary}>
                      {data.Owner?.owner}
                    </Label>
                    <Label fontSize="10px" color={Colors.textColorSecondary}>
                      {data.Owner?.address}
                    </Label>
                  </Box>
                </Box>

                {(data.Owner?.tokopedia ||
                  data.Owner?.shopee ||
                  data.Owner?.instagram ||
                  data.Owner?.optional) && (
                  <Label fontSize="14px" color={Colors.textSecondary}>
                    More Info
                  </Label>
                )}

                <Box display="flex" alignItems="center" gap="4px">
                  {data.Owner?.tokopedia && (
                    <IconButton
                      onClick={() =>
                        window.open(data.Owner?.tokopedia, "_blank")
                      }
                    >
                      <img
                        alt="tokopedia-icon"
                        src={Tokopedia}
                        width="36px"
                        height="36px"
                        style={{ objectFit: "contain" }}
                      />
                    </IconButton>
                  )}

                  {data.Owner?.shopee && (
                    <IconButton
                      onClick={() => window.open(data.Owner?.shopee, "_blank")}
                    >
                      <img
                        alt="shopee-icon"
                        src={Shopee}
                        width="36px"
                        height="36px"
                        style={{ objectFit: "contain" }}
                      />
                    </IconButton>
                  )}

                  {data.Owner?.instagram && (
                    <IconButton
                      onClick={() =>
                        window.open(
                          `https://www.instagram.com/${data.Owner?.instagram}`,
                          "_blank"
                        )
                      }
                    >
                      <img
                        alt="instagram-icon"
                        src={Instagram}
                        width="28px"
                        height="28px"
                        style={{ objectFit: "contain" }}
                      />
                    </IconButton>
                  )}

                  {data.Owner?.optional && (
                    <IconButton
                      onClick={() =>
                        window.open(data.Owner?.optional, "_blank")
                      }
                    >
                      <Link size={20} color={Colors.textColorPrimary} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Customer service */}
      <Box paddingX={laptop ? "48px" : "0px"} marginY="100px">
        <Box
          backgroundColor={Colors.textColorPrimary}
          padding="32px 48px"
          borderRadius="20px"
          display={laptop ? "flex" : "grid"}
          alignItems="center"
          justifyContent="center"
        >
          <Box width={laptop2 ? "659px" : "100%"}>
            <Typography
              type={laptop ? "heading2" : "heading5"}
              color={Colors.white}
            >
              Layanan Pertanyaan
            </Typography>
            <Label color={Colors.white} fontSize={laptop ? 16 : 12}>
              Silahkan hubungi Admin Website, jika terdapat masalah atau
              pertanyaan terkait Official Website RUMAH BISNIS MANDIRI
            </Label>
          </Box>

          <MyButton
            sx={{
              marginTop: laptop ? "0px" : "12px",
              background: Colors.textColorPrimary,
              fontFamily: "Anek Bangla",
              color: Colors.white,
              textTransform: "none",
              fontWeight: 700,
              fontSize: laptop ? "24px" : "18px",
              border: `1px solid ${Colors.white}`,
              borderRadius: "30px",
              padding: tablet ? "9px 48px" : "10px",
              transition: "0.5 all ease",
              "&:hover": {
                border: `1px solid ${Colors.primaryOrange100}`,
                color: Colors.primaryOrange100,
              },
            }}
            onClick={() =>
              window.open(
                "https://wa.me/6287718654796?text=Hai Admin Rumah Bisnis Mandiri, saya ingin bertanya",
                "_blank"
              )
            }
          >
            Hubungi Admin <Headset style={{ marginLeft: "8px" }} />
          </MyButton>
        </Box>
      </Box>
    </Container>
  );
};

export default ProductDetail;
